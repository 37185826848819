import { CatalogModel } from "../models/CatalogModel"

export const APP_NAME = "Glory Hair Braiding"
export const APP_EMAIL = "braiding73@yahoo.com"
export const APP_ADDRESS = "6320 N Tryon St Suite G, Charlotte, NC 28213"
export const APP_PHONE_NUMBER = "+1 704 231 4850"

export const catalogList: Array<CatalogModel> = [
    {
        name: "SENEGALESE TWISTS",
        url: "senegalese-twist/4.jpeg",
        urls: [
            "senegalese-twist/1.jpeg",
            "senegalese-twist/2.jpeg",
            "senegalese-twist/3.jpeg",
            "senegalese-twist/4.jpeg",
        ]
    },
    {
        name: "BOX BRAID",
        url: "box-braid/23.jpeg",
        urls: [
            "box-braid/1.jpeg",
            "box-braid/2.jpeg",
            "box-braid/3.jpeg",
            "box-braid/4.jpeg",
            "box-braid/5.jpeg",
            "box-braid/6.jpeg",
            "box-braid/7.jpeg",
            "box-braid/8.jpeg",
            "box-braid/9.jpeg",
            "box-braid/10.jpg",
            "box-braid/11.jpeg",
            "box-braid/12.jpeg",
            // "box-braid/13.jpeg",
            "box-braid/14.jpeg",
            "box-braid/15.jpeg",
            "box-braid/16.jpeg",
            // "box-braid/17.jpeg",
            "box-braid/23.jpeg",
            "box-braid/24.jpeg",
            "box-braid/25.jpeg",
            "box-braid/26.jpeg",
        ]
    },
    {
        name: "TRAVEL BRAID",
        url: "travel-braid/2.jpeg",
        urls: [
            "travel-braid/1.jpeg",
            "travel-braid/2.jpeg",
        ]
    },
    {
        name: "SPRING TWIST",
        url: "spring-twist/1.jpeg",
        urls: [
            "spring-twist/1.jpeg",
        ]
    },
    {
        name: "BOHEMIAN BOX BRAIDS",
        url: "bohemian-box-braids/1.jpeg",
        urls: [
            "bohemian-box-braids/1.jpeg"
        ]
    },
    {
        name: "BOX BRAIDS FRENCH CURL",
        url: "box-braids-french-curl/1.jpeg",
        urls: [
            "box-braids-french-curl/1.jpeg",
            "box-braids-french-curl/2.jpeg"
        ]
    }

    // {
    //     name: "SALON FRONT",
    //     url: "salon-front/1.jpg",
    //     urls: [
    //         "salon-front/1.jpg",
    //         "salon-front/2.jpg"
    //     ]
    // },
    // {
    //     name: "SALON DESIGN",
    //     url: "salon-interior/1.jpg",
    //     urls: [
    //         "salon-interior/1.jpg",
    //     ]
    // },
]